/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef, useState } from 'react';
import {
  consentMgmt,
  consentMgmtCheckbox,
  consentMgmtContents,
  consentMgmtEmailBox,
  consentMgmtErrorMessage,
  consentMgmtImageOne,
  consentMgmtImageTwo,
  consentMgmtLoadingImage,
  consentMgmtPhotoCredit,
  consentMgmtSuccessText
} from '../../components/Styles/consentManagement';
import { t } from '../../domain/services/configService';
import { newsletterApiService } from '../../domain/services/newsletterApiService';
import { isEmail } from '../../domain/utils/stringUtils';
import WithLayout from '../WithLayout';

const CHECKBOX = 'consent-mgmt__checkbox-input';
const TEXT_INPUT = 'consent-mgmt__text-input';
const LOADER_PATH = '/images/ng-logo-animated.gif';

const ConsentManagement = () => {
  const [state, setState] = useState({
    success: false,
    success_email: false,
    consent: null,
    isLoading: true,
    error: false,
    email: undefined
  });
  const emailInputRef = useRef();
  const emailErrorRef = useRef();
  const getParameter = (name, url) => {
    const thisUrl = url || window.location.href;
    const thisName = name.replace(/[[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${thisName}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(thisUrl);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  };
  const getConsentMetadata = () => {
    newsletterApiService
      .getConsentMetadata()
      .then(response => {
        if (response.status !== 200) {
          // eslint-disable-next-line no-console
          console.log('Error:', response);
          // eslint-disable-next-line no-throw-literal
          throw { error: response };
        }

        return response.json();
      })
      .then(data => {
        setState({ ...state, isLoading: false, consent: data });
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log('request failed', error);
        setState({ ...state, isLoading: false, error: true });
      });
  };
  useEffect(() => {
    const token = getParameter('token');
    const email = getParameter('email');
    if (email) {
      setState({ ...state, email });
    }
    if (token) {
      newsletterApiService
        .consentManagement(token)
        .then(response => {
          if (response.status !== 200) {
            // eslint-disable-next-line no-throw-literal
            throw { error: 'error' };
          }
          setState({
            ...state,
            isLoading: false,
            success: true,
            success_email: true
          });
        })
        // eslint-disable-next-line no-unused-vars
        .catch(_error => {
          getConsentMetadata();
        });
    } else {
      getConsentMetadata();
    }
  }, []);

  const submit = e => {
    e.preventDefault();

    let errors = false;
    const checkboxes = document.getElementsByClassName(CHECKBOX);
    const textInputs = document.getElementsByClassName(TEXT_INPUT);

    let i;

    // eslint-disable-next-line no-plusplus
    for (i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].required && !checkboxes[i].checked) {
        checkboxes[i].parentElement.parentElement
          .querySelector('h7')
          .classList.add('error');
        errors = true;
      } else {
        checkboxes[i].parentElement.parentElement
          .querySelector('h7')
          .classList.remove('error');
      }
    }

    // eslint-disable-next-line no-plusplus
    for (i = 0; i < textInputs.length; i++) {
      if (textInputs[i].required && textInputs[i].value === '') {
        checkboxes[i].classList.add('error');
        errors = true;
      } else {
        checkboxes[i].classList.remove('error');
      }
    }

    if (!errors && isEmail(emailInputRef.current.value)) {
      setState({ ...state, isLoading: true });
      emailErrorRef.current.style.display = 'none';
      const form = document.getElementsByClassName('consent-mgmt__form');
      const bodyData = {};
      // eslint-disable-next-line no-plusplus
      for (i = 0; i < form[0].elements.length; i++) {
        if (form[0].elements[i].name !== '') {
          if (form[0].elements[i].type === 'checkbox') {
            bodyData[form[0].elements[i].name] = form[0].elements[i].checked;
          } else {
            let inputValue = form[0].elements[i].value;
            if (inputValue === 'true') {
              inputValue = true;
            }
            bodyData[form[0].elements[i].name] = inputValue;
          }
        }
      }
      newsletterApiService
        .sendConsentManagementConfirmation(bodyData)
        .then(response => {
          if (response.status !== 200 && response.status !== 202) {
            // eslint-disable-next-line no-throw-literal
            throw 'error';
          }
          setState({ ...state, isLoading: false, success: true });
        })
        .catch(() => {
          // No errors shown to hide registered emails info.
          setState({
            ...state,
            isLoading: false,
            success: true,
            success_email: false
          });
        });
    } else if (!isEmail(emailInputRef.current.value)) {
      emailErrorRef.current.style.display = 'block';
    } else {
      emailErrorRef.current.style.display = 'none';
    }
  };

  return (
    <div className="consent-mgmt" css={consentMgmt}>
      {!state.success ? (
        <div>
          <div css={consentMgmtImageOne}>
            <img alt="" src="/images/newsletter__image-1.jpg" />
          </div>

          <div css={consentMgmtImageTwo}>
            <img alt="" src="/images/newsletter__image-2.jpg" />
          </div>

          <div css={consentMgmtContents}>
            <h3>{t('CONSIDERATION OF CONSENT')}</h3>

            <p>
              {t(
                'EDIT YOUR PREFERENCES HERE IF YOU WANT TO WITHDRAW YOUR CONSENT'
              )}
            </p>
            {state.isLoading && (
              <img
                css={consentMgmtLoadingImage}
                src={LOADER_PATH}
                alt={t('Loading...')}
              />
            )}
            {!state.isLoading && !state.error && (
              <form className="consent-mgmt__form" onSubmit={submit} noValidate>
                <input
                  ref={emailInputRef}
                  name="email"
                  aria-label="Email"
                  value={state.email}
                  css={consentMgmtEmailBox}
                  placeholder="email"
                />
                <button type="submit">{t('send')}</button>

                {state.consent &&
                  Object.keys(state.consent.consent.configuration).map(
                    (key, index) => {
                      const consent = state.consent.consent.configuration[key];
                      const inputRequired = consent.required ? ' *' : '';
                      let showInput = !consent.required;
                      if (typeof consent.consent_form !== 'undefined') {
                        showInput = consent.consent_form;
                      }
                      if (showInput) {
                        if (consent.type === 'checkbox') {
                          return (
                            <div
                              // eslint-disable-next-line react/no-array-index-key
                              key={`consent-mgmt__${index}`}
                              className="consent-mgmt__input"
                            >
                              <div
                                className="consent-mgmt__checkbox"
                                css={consentMgmtCheckbox}
                              >
                                <input
                                  type="checkbox"
                                  required={consent.required}
                                  className="consent-mgmt__checkbox-input"
                                  data-index={index}
                                  id={key}
                                  name={key}
                                  value="1"
                                />
                                <label htmlFor={key}>
                                  <span />
                                </label>
                              </div>
                              <h7
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{
                                  __html: t(consent.text) + t(inputRequired)
                                }}
                              />
                            </div>
                          );
                        }
                        if (consent.type === 'text') {
                          return (
                            <div
                              // eslint-disable-next-line react/no-array-index-key
                              key={`consent-mgmt__${index}`}
                              className="consent-mgmt__input consent-mgmt__input-text"
                            >
                              <input
                                className="consent-mgmt__text-input"
                                required={consent.required}
                                type="text"
                                data-index={index}
                                id={key}
                                name={key}
                                placeholder={consent.text + inputRequired}
                              />
                            </div>
                          );
                        }
                        if (consent.type === 'info') {
                          return (
                            <div
                              // eslint-disable-next-line react/no-array-index-key
                              key={`consent-mgmt__${index}`}
                              className="consent-mgmt__input consent-mgmt__input-text"
                            >
                              {consent.text}
                            </div>
                          );
                        }
                        if (consent.type === 'hidden') {
                          return (
                            <input
                              // eslint-disable-next-line react/no-array-index-key
                              key={`consent-mgmt__${index}`}
                              type="hidden"
                              data-index={index}
                              id={key}
                              name={key}
                              value={consent.text}
                            />
                          );
                        }
                      } else if (
                        consent.required &&
                        'newsletter' in consent === false
                      ) {
                        return (
                          <input
                            // eslint-disable-next-line react/no-array-index-key
                            key={`consent-mgmt__${index}`}
                            type="hidden"
                            data-index={index}
                            id={key}
                            name={key}
                            value
                          />
                        );
                      }
                      return null;
                    }
                  )}
                {state.consent.consent && (
                  <input
                    type="hidden"
                    id="region"
                    name="region"
                    value={state.consent.consent.region.code}
                  />
                )}
                {state.consent.privacyPolicyDate && (
                  <input
                    type="hidden"
                    id="privacyPolicyDate"
                    name="privacyPolicyDate"
                    value={state.consent.privacyPolicyDate}
                  />
                )}
              </form>
            )}
            <div
              className="consent-mgmt__error-message"
              css={consentMgmtErrorMessage}
            >
              <span ref={emailErrorRef}>
                {t('please enter a valid email address')}
              </span>
            </div>
          </div>

          <div css={consentMgmtPhotoCredit}>
            {t('PHOTO CREDIT')} {t('Joel Sartore, Photo Ark')}
          </div>
        </div>
      ) : (
        <div className="success">
          <div css={consentMgmtImageOne}>
            <img alt="" src="/images/newsletter__image-1.jpg" />
          </div>

          <div css={consentMgmtImageTwo}>
            <img alt="" src="/images/newsletter__image-2.jpg" />
          </div>

          <div css={consentMgmtContents}>
            <h5>{t('CONSIDERATION OF CONSENT')}</h5>

            <h3>{t('thanks')}</h3>
            {!state.success_email ? (
              <h6>
                {t(
                  'AN EMAIL HAS BEEN SENT TO YOUR ADDRESS TO CONFIRM THE CHANGE OF CONSENT'
                )}
              </h6>
            ) : (
              <p css={consentMgmtSuccessText}>
                {t('YOUR CONSENT SETTINGS HAVE BEEN CHANGED.')}
              </p>
            )}
          </div>

          <div css={consentMgmtPhotoCredit}>
            {t('PHOTO CREDIT')} {t('Joel Sartore, Photo Ark')}
          </div>
        </div>
      )}
    </div>
  );
};

export default WithLayout(ConsentManagement);
